// src/redux/emailFormSlice.js
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  email: '',
  orderNumber: '',
  orderNotes: '',
  action: 'In Production',
  progress: 'In Production',
  previewVisible: false,
  sendCopy: true,
  deliveryAddress: "",
  trackingLink: "",
  courier: "fedex",
  loading: false,
}; 

const emailFormSlice = createSlice({
  name: 'emailForm',
  initialState,
  reducers: {
    setLoading(state, action){
      state.loading = action.payload
    },
    setEmail(state, action) {
      state.email = action.payload;
    },
    setOrderNumber(state, action) {
      state.orderNumber = action.payload;
    },
    setOrderNotes(state, action) {
      state.orderNotes = action.payload;
    },
    setAction(state, action) {
      state.action = action.payload;
    },
    setProgress(state, action){
     state.progress = action.payload;
    },
    togglePreview(state) {
      state.previewVisible = !state.previewVisible;
    },
    setSendCopy(state, action) {
      state.sendCopy = action.payload;
    },
    resetForm(state) {
      return initialState; // Resets the form to its initial state
    },
    setDeliveryAddress(state, action){
      state.deliveryAddress = action.payload
    },
    setTrackingLink(state, action){
      state.trackingLink = action.payload
    },
    setCourier(state, action){
      state.courier = action.payload
    }
  },
});

export const {
  setEmail,
  setOrderNumber,
  setOrderNotes,
  setAction,
  setProgress,
  togglePreview,
  setSendCopy,
  resetForm,
  setLoading,
  setDeliveryAddress,
  setTrackingLink,
  setCourier
} = emailFormSlice.actions;

export default emailFormSlice.reducer;
