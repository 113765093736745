import React from "react";
import { useSelector } from "react-redux";

import quillDataFilter from "../lib/quillDataFilter";

export default function ModalContent() {
  const {
    orderNumber,
    orderNotes,
    action,
    progress,
    deliveryAddress,
    trackingLink,
    courier,
  } = useSelector((state) => state.emailForm);

  return (
    <table
      width="100%"
      style={{
        background: "#fff",
        padding: "20px",
        maxWidth: "600px",
        margin: "0 auto",
      }}
    >
      <tbody>
        <tr>
          <td>
            <img
              width="170px"
              src="https://cdn.shopify.com/s/files/1/0043/7318/4602/files/Logo_cc7e8c33-8e1f-4256-846e-9cbf9968306c.jpg?v=1726916956"
              alt="Logo"
              style={{ maxWidth: "80%", width: "170px" }}
            />
          </td>
          <td align="right">
            <img
              width="220px"
              src="https://cdn.shopify.com/s/files/1/0043/7318/4602/files/UK_Made.jpg?v=1726916956"
              alt="Logo"
              style={{ maxWidth: "80%", width: "220px" }}
            />
          </td>
        </tr>
        <tr>
          <td colSpan="2">
            <h2
              style={{
                backgroundColor: "#5c5ce6",
                color: "#fff",
                padding: "10px 0",
                textAlign: "center",
              }}
            >
              Your order #{orderNumber}
            </h2>
            <h3 style={{ color: "#100050", textAlign: "center" }}>
              Order status:
              <span style={{ color: "#5c5ce6", fontWeight: "bold" }}>
                {action == "In Production"? " "+progress : " "+action}
              </span>
            </h3>
          </td>
        </tr>
        <tr>
          <td
            colSpan="2"
            style={{ backgroundColor: "#f5f6f8", padding: "20px" }}
          >
            <table width="100%" style={{ borderCollapse: "collapse" }}>
              <tbody>
                {/**change as per email action */}
                {action == "In Production" ? (
                  <>
                    <tr>
                      <td style={{ backgroundColor: "#dce1e7" }}>
                        <h3
                          style={{
                            padding: "10px 20px",
                            margin: "0",
                            color: "#100050",
                          }}
                        >
                          Order Description
                        </h3>
                      </td>
                    </tr>
                    <tr style={{ background: "#fff" }}>
                      <td style={{ padding: "20px", color: "#100050" }}>
                        <p
                          className="orderNotes"
                          dangerouslySetInnerHTML={{
                            __html: quillDataFilter(orderNotes),
                          }}
                          style={{ margin: 0, padding: 0, color: "#100050" }}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td
                        style={{
                          borderBottom: "1px solid #dce1e7",
                          paddingBottom: "20px",
                          paddingTop: "20px",
                        }}
                      >
                        <table>
                          <tbody>
                            <tr>
                              <td>
                                <img
                                  width="30px"
                                  src="https://cdn.shopify.com/s/files/1/0043/7318/4602/files/warning.png?v=1726924774"
                                  alt="warning"
                                  style={{
                                    width: "30px",
                                    marginRight: "5px",
                                    objectFit: "contain",
                                  }}
                                />
                              </td>
                              <td>
                                <p
                                  style={{
                                    padding: 0,
                                    margin: 0,
                                    color: "#100050",
                                  }}
                                >
                                  <strong>Please note:</strong> At this stage in
                                  the order process, we are unable to change
                                  your artwork or order details.
                                </p>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </td>
                    </tr>
                    <tr>
                      <td style={{ paddingTop: "20px" }}>
                        <h4
                          style={{
                            color: "#100050",
                            marginBottom: "0",
                            paddingBottom: "0",
                            marginTop: "0",
                          }}
                        >
                          <strong>What happens next?</strong>
                        </h4>
                        <p
                          style={{
                            marginBottom: "20px",
                            color: "#100050",
                            marginTop: "0",
                            paddingTop: "0",
                          }}
                        >
                          You will receive a notification once your order has
                          been completed along with tracking information.
                        </p>
                        <p
                          style={{
                            marginBottom: "20px",
                            color: "#100050",
                            fontSize: "12px",
                          }}
                        >
                          <strong>
                            Please contact us immediately if you need to update
                            your delivery information.
                          </strong>
                        </p>
                      </td>
                    </tr>
                  </>
                ) : action == "Dispatched" ? (
                  <>
                    <tr>
                      <td style={{ backgroundColor: "#dce1e7" }}>
                        <h3
                          style={{
                            padding: "10px 20px",
                            margin: "0",
                            color: "#100050",
                          }}
                        >
                          Delivery Address
                        </h3>
                      </td>
                    </tr>
                    <tr style={{ background: "#fff" }}>
                      <td style={{ padding: "20px", color: "#100050" }}>
                        <p
                          className="orderNotes"
                          dangerouslySetInnerHTML={{
                            __html: quillDataFilter(deliveryAddress),
                          }}
                          style={{ margin: 0, padding: 0, color: "#100050" }}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td
                        style={{
                          paddingBottom: "30px",
                          paddingTop: "30px",
                        }}
                      >
                        <table
                          style={{
                            textAlign: "center",
                            margin: "0 auto",
                            width: "100%",
                            borderCollapse: "collapse",
                          }}
                        >
                          <tbody>
                            <tr>
                              <td align="center">
                                <a
                                  href={trackingLink}
                                  style={{
                                    padding: "10px 60px",
                                    margin: 0,
                                    color: "#fff",
                                    textDecoration: "none",
                                    textAlign: "center",
                                    fontWeight: "bold",
                                    backgroundColor: "#5c5ce6",
                                  }}
                                >
                                  Track My Order
                                </a>
                              </td>
                            </tr>
                            <tr>
                              <td height="20" style={{ lineHeight: "20px" }}>
                                &nbsp;
                              </td>
                            </tr>
                            <tr>
                              <td>
                                Courier: <strong>{courier}</strong>
                              </td>
                            </tr>
                            <tr>
                              <td height="10" style={{ lineHeight: "10px" }}>
                                &nbsp;
                              </td>
                            </tr>
                            <tr
                              style={{
                                backgroundColor: "#ff3600",
                                color: "#fff",
                                width: "100%",
                                fontWeight: "bold",
                              }}
                            >
                              <td>
                                <p style={{ padding: "5px 0", margin: "0" }}>
                                  IMPORTANT DELIVERY INFORMATION - PLEASE READ
                                </p>
                              </td>
                            </tr>
                            <tr style={{backgroundColor: "#fff", textAlign: "left",}}>
                              <td >
                                <h3 style={{margin: 0, padding: "0px 20px", paddingTop: '20px'}}>
                                  <strong>Upon delivery:</strong>
                                </h3>
                              </td>
                            </tr>
                            <tr style={{backgroundColor: "#fff", textAlign: "left",}}>
                              <td>
                                <p style={{margin: 0, padding: "10px 20px"}}>
                                  If you (or your customer) notice any damage to
                                  the packaging, or if the delivery driver does
                                  not allow you enough time and opportunity to
                                  thoroughly inspect the condition of the
                                  package contents, it is essential that the
                                  consignment is accepted only as 'damaged'.
                                  This allows us to make a claim with the
                                  courier if the contents are later found to be
                                  damaged. <br/><br/>Providing your name to the delivery
                                  driver is regarded by the courier(s) as
                                  acceptance of the consignment in good
                                  condition, and they will not be held
                                  responsible for any issues. If this is done
                                  without marking the consignment as 'damaged',
                                  and the contents are later found to be
                                  damaged, we will be unable to make a claim
                                  with the courier, which may affect your
                                  eligibility for a re-print.
                                </p>
                              </td>
                            </tr>

                            <tr style={{backgroundColor: "#fff", textAlign: "left",}}>
                              <td >
                                <h3 style={{margin: 0, padding: "0px 20px", paddingTop: '20px'}}>
                                  <strong>Make sure someone will be in</strong>
                                </h3>
                              </td>
                            </tr>
                            <tr style={{backgroundColor: "#fff", textAlign: "left",}}>
                              <td>
                                <p style={{margin: 0, padding: "10px 20px"}}>
                                If you miss your delivery or won’t be in, you can try updating your delivery
options via the tracking link provided. As we use various couriers, some
redelivery options may not be available. If you miss your delivery, please
contact the courier directly to arrange redelivery.
                                </p>
                              </td>
                            </tr>
                            <tr style={{backgroundColor: "#fff"}}>
                              <td height="10" style={{ lineHeight: "10px" }}>
                                &nbsp;
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </td>
                    </tr>
        
                  </>
                ) : (
                  ""
                )}

                {/**change as per email action */}
                {/* Contact Section */}
                <tr>
                  <td align="center">
                    <table
                      align="center"
                      style={{
                        margin: "0 auto",
                        borderCollapse: "collapse",
                        width: "100%",
                      }}
                    >
                      <tbody>
                        <tr style={{ backgroundColor: "#dce1e7" }}>
                          <td colSpan={"3"}>
                            <h3
                              style={{
                                padding: "10px 20px",
                                margin: 0,
                                color: "#100050",
                                textAlign: "center",
                              }}
                            >
                              Got a question?
                            </h3>
                          </td>
                        </tr>

                        <tr style={{ background: "#fff" }}>
                          <td
                            align="center"
                            style={{
                              textAlign: "center",
                              verticalAlign: "top",
                              padding: "10px",
                              width: "33.33%",
                            }}
                          >
                            <a
                              href="tel:0333 2100 441"
                              style={{
                                display: "contents",
                                color: "#100050",
                                border: "none",
                                textDecoration: "none",
                                fontWeight: "bold",
                                padding: "10px",
                              }}
                            >
                              <table style={{ width: "100%" }}>
                                <tbody>
                                  <tr>
                                    <td align="center">
                                      <img
                                        width="30px"
                                        src="https://cdn.shopify.com/s/files/1/0043/7318/4602/files/Phone_46e38967-1016-4a3a-a830-66b5d04c9f09.jpg?v=1726916956"
                                        alt="Phone"
                                        style={{
                                          width: "30px",
                                          margin: "0 auto",
                                          display: "block",
                                        }}
                                      />
                                    </td>
                                  </tr>
                                  <tr>
                                    <td
                                      align="center"
                                      style={{ color: "#100050" }}
                                    >
                                      <span>Call us</span>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </a>
                          </td>
                          <td
                            align="center"
                            style={{
                              textAlign: "center",
                              verticalAlign: "top",
                              padding: "10px",
                              width: "33.33%",
                            }}
                          >
                            <a
                              href="mailto:team@oneprinter.co.uk"
                              style={{
                                display: "contents",
                                color: "#100050",
                                border: "none",
                                textDecoration: "none",
                                fontWeight: "bold",
                                padding: "10px",
                              }}
                            >
                              <table style={{ width: "100%" }}>
                                <tbody>
                                  <tr>
                                    <td align="center">
                                      <img
                                        src="https://cdn.shopify.com/s/files/1/0043/7318/4602/files/Email_251a56e8-7ae9-4b31-927c-b474280cbd58.jpg?v=1726916956"
                                        alt="Email"
                                        width="30px"
                                        style={{
                                          width: "30px",
                                          margin: "0 auto",
                                          display: "block",
                                        }}
                                      />
                                    </td>
                                  </tr>
                                  <tr>
                                    <td
                                      align="center"
                                      style={{ color: "#100050" }}
                                    >
                                      <span>Email us</span>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </a>
                          </td>
                          <td
                            align="center"
                            style={{
                              textAlign: "center",
                              verticalAlign: "top",
                              padding: "10px",
                              width: "33.33%",
                            }}
                          >
                            <a
                              href="https://www.oneprinter.co.uk/pages/delivery-information"
                              style={{
                                display: "contents",
                                color: "#100050",
                                border: "none",
                                textDecoration: "none",
                                fontWeight: "bold",
                                padding: "10px",
                              }}
                            >
                              <table style={{ width: "100%" }}>
                                <tbody>
                                  <tr>
                                    <td align="center">
                                      <img
                                        src="https://cdn.shopify.com/s/files/1/0043/7318/4602/files/Delivery.jpg?v=1726916956"
                                        alt="Delivery Info"
                                        width="30px"
                                        style={{
                                          width: "30px",
                                          margin: "0 auto",
                                          display: "block",
                                        }}
                                      />
                                    </td>
                                  </tr>
                                  <tr>
                                    <td
                                      align="center"
                                      style={{ color: "#100050" }}
                                    >
                                      <span>Delivery info</span>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </a>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </td>
                </tr>

                {/* Company Info */}
                <tr>
                  <td height="20" style={{ lineHeight: "20px" }}>
                    &nbsp;
                  </td>
                </tr>
                <tr>
                  <td
                    align="center"
                    style={{
                      fontSize: "12px",
                      textAlign: "center",
                      padding: "0 20px",
                      color: "#100050",
                    }}
                  >
                    <p style={{ margin: "0" }}>
                      One Printer Ltd, Head Office: Unit 4 Crusader Court, Eagle
                      Business Park, Harrier Way, Yaxley, Peterborough, PE7 3PU.
                      Company number: 11832710. Copyright © 2024, All rights
                      reserved.{" "}
                      <a href="#" style={{ color: "#100050" }}>
                        Please click here to unsubscribe.
                      </a>
                    </p>
                  </td>
                </tr>
              </tbody>
            </table>
          </td>
        </tr>
      </tbody>
    </table>
  );
}
