import DOMPurify from "dompurify";

 function quillDataFilter(orderNotes){
      // Your sanitized HTML content
  const rawHTML = orderNotes || "No additional details.";
  const sanitizedHTML = DOMPurify.sanitize(rawHTML);

  // Convert the sanitized HTML string into a DOM object for manipulation
  const parser = new DOMParser();
  const doc = parser.parseFromString(sanitizedHTML, "text/html");

  // Apply custom styles to specific tags
  const paragraphs = doc.querySelectorAll("p");
  paragraphs.forEach((p) => {
    p.style.margin = "0";
    p.style.padding = "0";
    p.style.color = "#100050";
  });

  const headings = doc.querySelectorAll("h1, h2, h3, h4, h5, h6");
  headings.forEach((h) => {
    h.style.color = "#100050";
  });

  // Serialize the modified DOM back into a string
  return doc.body.innerHTML;
}

export default quillDataFilter;