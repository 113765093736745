import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars } from '@fortawesome/free-solid-svg-icons';

export default function Header({ setToken }) {
  const navigate = useNavigate();
  const [dropdownOpen, setDropdownOpen] = useState(false);
  
  const handleDropdownToggle = () => {
    setDropdownOpen(!dropdownOpen);
  };

  const handleLogout = () => {
    localStorage.removeItem('token');
    setToken(null);
    navigate('/login');
  };
  
  const handleEmailLogs = () => {
    navigate('/email-logs');
  };

  return (
    <div className="header">
      <div className="logo" style={{ padding: '0 60px' }}>
        <Link to="/" style={{ display: 'contents' }}>
          <img src="logo.svg" alt="Logo" />
        </Link>
        <h1>Order Update System</h1>
        <div className="menu-icon" onClick={handleDropdownToggle} style={{ cursor: 'pointer' }}>
          <FontAwesomeIcon icon={faBars} size="2x" />
        </div>
        {dropdownOpen && (
          <div className="dropdown-menu">
            <button onClick={handleEmailLogs}>Email Logs</button>
            <button onClick={handleLogout}>Logout</button>
          </div>
        )}
      </div>
    </div>
  );
}
