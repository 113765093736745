import React, { useState } from 'react';
import Modal from 'react-modal';
import '../App.css';
import Form from './Form';
import { useDispatch, useSelector } from 'react-redux';
import { togglePreview, setLoading } from '../redux/slice/emailFormSlice'; 
import ModalContent from './ModalContent';
import axios from 'axios';
import ReactDOMServer from 'react-dom/server';
import { toast } from 'react-toastify';
import { store } from '../redux/store';
import { Provider } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { Link, useNavigate } from 'react-router-dom';
import Header from './Header';


const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    width: '60%',
    height: '70%'
  },
};

const HomePage = ({setToken}) => {
  const dispatch = useDispatch();
  const { email, orderNumber, orderNotes, action, previewVisible, sendCopy, loading } = useSelector(
    (state) => state.emailForm
  );




  const handleClosePreview = () => {
    dispatch(togglePreview(false));
  };

  const handleSendEmail = async (e) => {
    e.preventDefault();
    if (!email || !orderNumber) {
      toast.warning("Please fill in all required fields.")
      return;
    }
    const modalHTML = ReactDOMServer.renderToStaticMarkup(
      <Provider store={store}>
        <ModalContent />
      </Provider>
    );

    try {
      dispatch(setLoading(true));
      const promise = axios.post('https://www.one-printer.co.uk/wp-json/order-email/v1/send-email', {
        action: action,
        email: email,
        orderNumber: orderNumber,
        sendCopy: sendCopy,
        content: modalHTML
      });

      toast.promise(
        promise,
        {
          pending: 'Sending email...',
          success: 'Email sent successfully',
          error: 'Error sending email'
        }
      );

      await promise;
    } catch (error) {
      console.error('Error sending email:', error);
    } finally {
      dispatch(setLoading(false));
    }
  };

  return (
    <div className="container">
      <Header setToken={setToken} />
      <div className='form_modal form_modal_desktop'>
        <Form handleSendEmail={handleSendEmail} />
        <div className="max_width desktop_modal">
          <div className='table_wrap'>
            <ModalContent />
          </div>
        </div>
      </div>
      <Modal
        isOpen={previewVisible}
        onRequestClose={handleClosePreview}
        style={customStyles}
        contentLabel="Email Preview Modal"
      >
        <span className="close-btn" onClick={handleClosePreview}>&times;</span>
        <ModalContent />
      </Modal>
    </div>
  );
};

export default HomePage;
