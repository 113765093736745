import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  setEmail,
  setOrderNumber,
  setOrderNotes,
  setAction,
  setProgress,
  togglePreview,
  setSendCopy,
  setDeliveryAddress,
  setTrackingLink,
  setCourier
} from "../redux/slice/emailFormSlice";
import ForwardedReactQuill from "./ForwardedReactQuill";

export default function Form({ handleSendEmail }) {
  const dispatch = useDispatch();
  const { email, orderNumber, orderNotes, action, progress, previewVisible, sendCopy, loading, deliveryAddress,trackingLink,courier } = useSelector((state) => state.emailForm);

  const handlePreview = () => {
    // Show the preview modal
    dispatch(togglePreview(true));
  };

  return (
    <div className="body_container max_width con">
      <form className="form" onSubmit={handleSendEmail}>
        <div className="form-group">
          <label>Action</label>
          <select
            className="form-control"
            value={action}
            onChange={(e) => dispatch(setAction(e.target.value))}
          >
            <option value="In Production">Order Update - In Production</option>
            <option value="Dispatched">Order Update - Dispatched</option>
          </select>
        </div>

        { action == 'In Production'?(
        <div className="form-group">
          <label>Progress</label>
          <select
            className="form-control"
            value={progress}
            onChange={(e) => dispatch(setProgress(e.target.value))}
          >
            <option value="In Production">In Production</option>
            <option value="Completed">Completed</option>
            <option value="On Hold - Artwork Issue">On Hold - Artwork Issue</option>
            <option value="On Hold - Awaiting Artwork">On Hold - Awaiting Artwork</option>
            <option value="Awaiting Payment">Awaiting Payment</option>
          </select>
        </div>
          ): ""
        }
        <div className="form-group">
          <label>Customer Email Address</label>
          <input
            type="email"
            className="form-control"
            placeholder="Enter Customer Email"
            value={email}
            onChange={(e) => dispatch(setEmail(e.target.value))}
            required
          />
        </div>
        {
         action == 'In Production'?(
            <div>
            <div className="form-group">
              <label>Order Number</label>
              <input
                type="text"
                className="form-control"
                placeholder="Enter Order Number"
                value={orderNumber}
                onChange={(e) => dispatch(setOrderNumber(e.target.value))}
                required
              />
            </div>
  
            <div className="form-group">
              <label>Order Notes</label>
              <ForwardedReactQuill
                theme="snow"
                value={orderNotes}
                onChange={(content) => dispatch(setOrderNotes(content))}
                className="quill-editor"
              />
            </div>
          </div>
  
         ): action == 'Dispatched'? (
            <div>
             <div className="form-group">
              <label>Order Number</label>
              <input
                type="text"
                className="form-control"
                placeholder="Enter Order Number"
                value={orderNumber}
                onChange={(e) => dispatch(setOrderNumber(e.target.value))}
                required
              />
            </div> 
  
            <div className="form-group">
              <label>Delivery Address</label>
              <ForwardedReactQuill
                theme="snow"
                value={deliveryAddress}
                onChange={(content) => dispatch(setDeliveryAddress(content))}
                className="quill-editor"
              /> 
            </div>
            <div className="form-group">
              <label>Tracking Link (for tracking button)</label>
              <input
                type="url"
                className="form-control"
                placeholder="Enter Tracking Link"
                value={trackingLink}
                onChange={(e) => dispatch(setTrackingLink(e.target.value))}
                required
              />
            </div>
        <div className="form-group">
          <label>Courier</label>
          <select
            className="form-control"
            value={courier}
            onChange={(e) => dispatch(setCourier(e.target.value))}
          >
<option value="FedEx">FedEx</option>
<option value="DPD">DPD</option>
<option value="DX Freight">DX Freight</option>
<option value="Royal Mail">Royal Mail</option>
<option value="DHL">DHL</option>
<option value="Parcel Force">Parcel Force</option>
<option value="TNT UK">TNT UK</option>
<option value="Hermes">Hermes</option>
<option value="Amazon">Amazon</option>
<option value="Dedicated Courier">Dedicated Courier</option>
          </select>
        </div>

          </div>
  
         ): ""   
        }

        <div className="form-actions">
          <button
            type="button"
            className="btn preview-btn"
            onClick={handlePreview}
          >
            Preview Email
          </button>
          {console.log(loading)}
          <button
          disabled = {loading? "disabled": ""}
          type="submit" className="btn submit-btn">
            Send Email
          </button>
        </div>
      </form>

      <div className="footer">
        <input 
            onChange={(e) => {dispatch(setSendCopy(e.target.checked))}} 
            type="checkbox" 
            id="sendCopy" 
            name="sendCopy" 
            defaultChecked 
        />
        <label htmlFor="sendCopy">Send copy to team@oneprinter.co.uk</label>

      </div>
    </div>
  );
}
