import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useTable, useSortBy, usePagination, useGlobalFilter } from 'react-table';
import Header from './Header';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

const EmailLogTable = ({setToken}) => {
  const [emailData, setEmailData] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get('https://www.one-printer.co.uk/wp-json/order-email/v1/email-logs');
        setEmailData(response.data);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching the email data:', error);
        setLoading(false);
      }
    };
    fetchData();
  }, []);

  const data = React.useMemo(() => emailData, [emailData]);

  const columns = React.useMemo(() => [
    {
      Header: 'Action',
      accessor: 'order_action',
    },
    {
      Header: 'Email',
      accessor: 'email',
    },
    {
      Header: 'Order Number',
      accessor: 'order_number',
    },
    {
      Header: 'Status',
      accessor: 'status',
    },
    {
      Header: 'Date',
      accessor: 'sent_time',
      Cell: ({ value }) => {
        const date = new Date(value);
        return `${date.toLocaleDateString()} ${date.toLocaleTimeString()}`;
      },
    },
  ], []);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    setGlobalFilter,
    state: { pageIndex, pageSize },
    previousPage,
    nextPage,
    canPreviousPage,
    canNextPage,
  } = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0 },
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  // if (loading) {
  //   return (
  //     <div className="loader">
  //       <div className="wave">
  //         <div></div>
  //         <div></div>
  //         <div></div>
  //         <div></div>
  //       </div>
  //     </div>
  //   );
  // }

  return (
    <div className="email_logs_container email_logs_table">
      <Header setToken={setToken} />
      <div className="table_wrapper">
        <input
          className="search_emails"
          type="text"
          placeholder="Search"
          onChange={e => setGlobalFilter(e.target.value || undefined)}
        />
        {
          loading == false?(
            <table {...getTableProps()}>
            <thead>
              {headerGroups.map(headerGroup => {
                const { key, ...restHeaderGroupProps } = headerGroup.getHeaderGroupProps();
                return (
                  <tr key={key} {...restHeaderGroupProps}>
                    {headerGroup.headers.map(column => {
                      const { key, ...restColumnProps } = column.getHeaderProps(column.getSortByToggleProps());
                      return (
                        <th key={key} {...restColumnProps}>
                          {column.render('Header')}
                          <span>
                            {column.isSorted ? (column.isSortedDesc ? ' 🔽' : ' 🔼') : ''}
                          </span>
                        </th>
                      );
                    })}
                  </tr>
                );
              })}
            </thead>
            <tbody {...getTableBodyProps()}>
              {page.map(row => {
                prepareRow(row);
                const { key, ...restRowProps } = row.getRowProps();
                return (
                  <tr key={key} {...restRowProps}>
                    {row.cells.map(cell => {
                      const { key, ...restCellProps } = cell.getCellProps();
                      return (
                        <td key={key} {...restCellProps}>{cell.render('Cell')}</td>
                      );
                    })}
                  </tr>
                );
              })}
            </tbody>
          </table>
          ): (
            <Skeleton count={15} />
          )
        }

        <div>
          <button onClick={() => previousPage()} disabled={!canPreviousPage}>
            Previous
          </button>
          <button onClick={() => nextPage()} disabled={!canNextPage}>
            Next
          </button>
          <span>
            Page{' '}
            <strong>
              {pageIndex + 1} of {Math.ceil(data.length / pageSize)}
            </strong>
          </span>
        </div>
      </div>
    </div>
  );
};

export default EmailLogTable;
