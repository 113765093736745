import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import Login from './components/LoginPage';
import HomePage from './components/HomePage';
import EmailLogTable from './components/EmailLogs';

function App() {
  const [token, setToken] = useState(localStorage.getItem('token')); // Initialize state with token from localStorage

 // console.log('token',token);

  return (
    <Router>
      <Routes>
        {/* Redirect logged in users away from the login page */}
        <Route path="/login" element={token ? <Navigate to="/" /> : <Login setToken={setToken} />} />

        {/* Home page route */}
        <Route path="/" element={token ? <HomePage setToken={setToken} /> : <Navigate to="/login" />} />
        <Route path="/email-logs" element={token ? <EmailLogTable setToken={setToken} /> : <Navigate to="/login" />} />

        {/* Catch-all route to handle undefined paths (404) */}
        <Route path="*" element={<Navigate to="/login" />} />
      </Routes>
    </Router>
  );
}

export default App;
