import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { togglePreview, setLoading } from '../redux/slice/emailFormSlice'; 
import { useDispatch, useSelector } from 'react-redux';

const Login = ({setToken}) => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { loading } = useSelector((state) => state.emailForm);

  const handleLogin = async () => {
    try {
      dispatch(setLoading(true));
      const authPromise = axios.post('https://www.one-printer.co.uk/wp-json/jwt-auth/v1/token', {
        username,
        password,
      });

      toast.promise(authPromise, {
        pending: 'Login in progress...',
     //   success: 'You have successfully logged in',
        error: 'Invalid credentials',
      });

      const authResponse = await authPromise;
      const { token, roles } = authResponse.data;

        if (roles.includes('administrator')) {
          localStorage.setItem('token', token);
          setToken(localStorage.getItem('token'));
          navigate('/');
          toast.success('Login successful');
        } else {
       //   throw new Error('Access is restricted to administrators only.');
          toast.warning('Access is restricted to administrators only.');
        }


    } catch (err) {
      console.log(err.message);
    } finally {
      dispatch(setLoading(false));
    }
  };

  return (
    <div className="login-container">
      <div className="login-box">
        <div className="login-header">
          <div className="logo">
            <img src='/logo.jpg' alt="Logo" />
          </div>
        </div>

        <h2 className="login-title">Login to Order Update System</h2>

        <div className="form-group">
          <input
            type="text"
            placeholder="Username"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            className="login-input"
          />
        </div>
        <div className="form-group">
          <input
            type="password"
            placeholder="Password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            className="login-input"
          />
        </div>

        <button 
          disabled={loading}
          onClick={handleLogin} 
          className="login-button"
        >
          LOG IN
        </button>
      </div>
    </div>
  );
};

export default Login;
